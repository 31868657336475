import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { Alert, Collapse, Card, CardBody } from "reactstrap";
import _ from "lodash";
import TeamScores from "../../components/team/TeamScores";
import { afl, soccer } from "../../data/sports";
import { LeagueWrapper } from "./LeagueWrapper";

const Team = ({
  teamName,
  results,
  score,
  transfers,
  compPlayers,
  route,
  competition,
  rounds
}) => {
  const round = competition === "wsl" ? 11 : competition === "nwsl" ? 25 : competition === "euro" ? 6 : competition === "uwcl" ? 6 : competition === "wwc" ? 4 : competition === "aflw" ? 10 : 14;
  const sport = competition === "aflw" ? afl : soccer;
  const roundName = `round-${round}`;
  const [isOpen, setOpen] = useState(false);
  const playerList = (results &&
  results[roundName] &&
  results[roundName].players
    ? results[roundName].players
    : []
  ).map(r => ({
    ...compPlayers.find(p => p.playerId === r.playerId),
    ...r
  }));
  const transferPenalties = (transfers && transfers[roundName]) || 0;
  const points = score || {};
  return (
    <>
      <tr>
        <td>
          <a
            onClick={e => {
              e.preventDefault();
              setOpen(!isOpen);
            }}
            href=""
          >
            {teamName}{" "}
          </a>

          <Collapse isOpen={isOpen}>
            <Card>
              <CardBody>
                <h5>Round {round} Lineup</h5>
                <TeamScores
                  playerList={playerList}
                  transferPenalty={transferPenalties}
                  sport={sport}
                />
              </CardBody>
            </Card>
          </Collapse>
        </td>
        {rounds.map(r => (
          <td>{points[`round-${r}`] || 0}</td>
        ))}
        <th>{points.total}</th>
      </tr>
    </>
  );
};

const League = ({ route }) => {
  const routeParts = route.url.pathname.split("/");

  const leagueCode = routeParts[routeParts.length - 1];
  const competition =
    routeParts.length > 3
      ? routeParts[1] === "wl"
        ? "wleague"
        : routeParts[1]
      : "wsl";

  const [firstRound, setFirstRound] = useState(1);
  const [lastRound, setLastRound] = useState(1);

  const [league, setLeague] = useState(null);
  const [players, setPlayers] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      const p = await API.get("players", `/${competition}/players`);
      setPlayers(p);

      const result = await API.get(
        "leagues",
        `/${competition}/leagues/${leagueCode}`
      );

      if (result.firstRound) {
        setFirstRound(result.firstRound);
      }

      if (result.lastRound) {
        setLastRound(result.lastRound);
      } else if (result.teams) {
        setLastRound(
          result.teams
            .map(t =>
              Object.keys(t.score || {})
                .map(s => (s.startsWith("round-") ? parseInt(s.substr(6)) : 0))
                .reduce((a, b) => Math.max(a, b), 0)
            )
            .reduce((a, b) => Math.max(a, b), 0)
        );
      }
      setLeague(result);
    })();
  }, [route.url.pathname, setFirstRound, setLastRound]);

  const rounds = [
    ...Array(firstRound <= lastRound ? 1 + lastRound - firstRound : 0)
  ].map((x, i) => firstRound + i);
  const recalculatedTotals = league && league.teams && league.teams.map(({ score, ...team }) => ({
    ...team,
    score: {
      ...score,
      total: rounds.reduce(
        (sum, r) => sum + ((score || {})[`round-${r}`] || 0),
        0
      )
    }
  }));

  return error ? (
    <Alert className="mt-3" color="danger">
      {error}
    </Alert>
  ) : league !== null ? (
    <LeagueWrapper league={league} competition={competition}>
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th>Team Name</th>
              {rounds.map(r => (
                <th>R{r}</th>
              ))}
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {_.chain(recalculatedTotals).sortBy(t => -t.score["total"])
                .map((team, i) => (
                  <Team
                    {...team}
                    compPlayers={players}
                    key={i}
                    competition={competition}
                    rounds={rounds}
                  />
                ))
                .value()}
          </tbody>
        </table>
    </LeagueWrapper>
  ) : (
    <h3 className="container">Loading...</h3>
  );
};

export default League;
